import React from 'react';
import { cards } from '../scripts/card-data.js';
import { Footer } from './components.js';

function Learn(props) {

    const cardStack = cards.map(card => {
        return (
            <div key={card.key} className='learn-card'>
                <img className='learn-card-background' src={card.backgroundImage} alt={card.alt} />
                <div className='learn-card-content'>
                    <p className='learn-card-title'>{card.name}</p>
                    <button className='learn-card-button' data-cardtext={card.description} data-cardname={card.name} onClick={props.toggleModal
                    }>More</button>
                </div>
            </div>
    )});

    function Modal() {
        return (
            <div className='modal' id='learn-card-modal'>
                <div className='learn-card-modal-content'>
                    <h1 className='modal-header'>{props.modalName}</h1>
                    <p className='modal-text'>{props.modalText}</p>
                    <button onClick={props.toggleModal}>Close</button>
                </div>
            </div>
        )
    }
    
    
    return (
        <div id='app'>
            <div id='radical'>
                <main>
                    <h1>Building a Future vs. Building a Solution</h1>
                    <div id='learn-content'>
                        <p className='learn'>Many decision makers mistakenly believe that custom applications are expensive, time-consuming, and excessive for their needs, often opting for well-known brand solutions instead. In doing so, they risk investing more time and money in customizing their "out-of-the-box" solution than they would in building one from scratch.</p>
                        <p className='learn'>Custom is not for everyone. If your organization's operations align neatly with industry standards, a pre-built solution will likely suffice.</p>
                        <p className='learn'>However, if the creativity and effectiveness of your organization's core processes are what differentiate you from your competitors, a tailored technology solution is essential. And, if you're building custom anyway, why do it on someone else's platform?</p>
                        <h2>Some concepts to get you started</h2>
                    </div>
                    <div className='card-container'>{cardStack}</div>
                    { props.modalStatus ? <Modal /> : <></> }
                </main>
            </div>
            <Footer />
        </div>
)};

export default Learn;