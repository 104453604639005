const validateForm = (e) => {
    const emailPattern = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/;
    const pnumberPattern = /^[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/;
    
    const emailInput = e.target.email.value;
    const pnumberInput = e.target.pnumber.value
    const preferenceInput = e.target.contactMethod.value
    
    if (preferenceInput === 'email') {
        if (!emailPattern.test(emailInput)) {
            return false;
        } else {
            return true;
        }
    } else {
        if (!pnumberPattern.test(pnumberInput)) {
            return false;
        } else {
            return true;
        };
    };
};

export default validateForm;