import React from 'react';
import { Link } from 'react-router-dom';

export function Footer() {
    const pages = [
        {
            key: 0,
            name: 'privacy policy',
            navId: 'privacy'
        }
    ];
    const footerLinks = pages.map(page => {
        return (
            <Link key={page.key} className='jump-links' to={`/${page.navId}`}>{page.name}</Link>
        )
    });

    return (
            <footer>
                {footerLinks}
                <p className='copyr'>&copy; 2024 CGQ Technologies, LLC</p>
            </footer>
    )
};