import React from 'react';
import { Link } from 'react-router-dom';
import { Footer } from './components.js';

function About() {
    return (
        <div id='app'>
            <div id='radical'>
                <header><h1>Get to Know Us</h1></header>
                <main>
                    <p className='about-content'>CGQ is an abbreviation for "cogency quotient." We started kicking around the idea of organizational cogency - as measured by an organization's cogency quotient - some time ago. We sought to understand overall organizational success through the lens of efficacy and value by studying successful companies in all areas of business and industry. What we found is that success is actually quite abundant. True, there is only one <a className='inline-link' href='https://www.aramco.com/en' target='_blank' rel='noreferrer'>Saudi Aramco</a>, but success is not a $150 billion valuation exclusively. An organization's success is primarily dependent on its goals and execution. We have known real estate agents more successful than startup founders, grocers more successful than quants. Mostly, success is about figuring out what you want to do, and then doing it well. Today, the "doing it well" part almost always involves implementing technology in some crucial facet of your business operations.</p>
                    <p className='about-content'>Our core business at CGQ is not actually technology, it's solving problems. Implemeting technology solutions requires an organization to take a deep dive into an their structure, goals, offerings, and competitive edge to make a future-defining decision. Our earnest intention is to assist in that process, even if sometimes the result is advising that our custom solutions may not be the best fit. We are committed to fostering long-term partnerships based on integrity and mutual benefit because strategic evaluation isn't a one-off task; it's a continuous process. Even if our solutions aren't ideal today, the landscape can change tomorrow. We believe in cultivating relationships that endure and evolve with your organization's growth and challenges.</p>
                    <Link to='/contact'><button>Contact Us</button></Link>
                    <hr />
                    <p className='definition'>
                        <strong>cogency quotient</strong> /'kōjənsē kwōSH(ə)nt/ n. a metric or set of metrics by which an organization’s health and value proposition is measured.
                    </p>
                    <p className='definition'>
                        Formal cogency quotients are often proprietary, and encompass factors such as leadership, governance, risk exposure, stakeholder relationships, operational efficiency, R&D investment, market positioning, and financial performance. The success of management consulting firms such as <a className='inline-link' href='https://www.mckinsey.com/' target='_blank' rel='noreferrer'>McKinsey & Company</a>, holding companies such as <a className='inline-link' href='https://www.berkshirehathaway.com/' target='_blank' rel='noreferrer'>Berkshire Hathaway</a>, and venture capital firms such as <a className='inline-link' href='https://www.a16z.com/' target='_blank' rel='noreferrer'>Andreessen Horowitz</a> are dependent on algorithms and insights that determine the cogency quotients of organizations.
                    </p>
                    <p className='definition'>
                        Cogency quotients can also be informal and personal, calculated subconsciously by an individual consumer. At this level, a cogency quotient can encompass factors such as unique benefit, relevancy, timeliness, trustworthiness, and market differentiation.
                    </p>
                    <h2 className='colorful'>What is your organization's Cogency Quotient?</h2>
                    {/*<h2>So, why trust us?</h2>
                    <h3>Because we are those nerds.</h3>
                    <p>We are business nerds. We are finance nerds. We are technology nerds. We are design nerds.</p>
                    <p>We are the kind of nerds that lay awake at night thinking about efficiency.</p>
                    <p>We are the kind of nerds that work in our free time. We also work during work time. Because we are the kind of nerds that are so excited about what we do, we work all the time, just for the fun of it.</p>*/}
                </main>
            </div>
            <Footer />
        </div>
)};

export default About;