import { React, useState, useRef } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import emailjs from "@emailjs/browser";
import Layout from './pages/layout.js';
import Home from './pages/home.js';
import About from './pages/about.js';
import Learn from './pages/learn.js';
import Contact from './pages/contact.js';
import Privacy from './pages/privacy.js';
import validateForm from './scripts/form-validation.js';

function App() {
  const [modalStatus, setModalStatus] = useState(false);
  const [cardModalName, setCardModalName] = useState(false);
  const [cardModalText, setCardModalText] = useState(false);
  const [contacted, setContacted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState(false); 
  const form = useRef();

  const handleModal = (e) => {
    e.preventDefault();
    setModalStatus(modalStatus => !modalStatus);
    setCardModalName(e.target.dataset.cardname);
    setCardModalText(e.target.dataset.cardtext);
  };

  const handleSendEmail = async () => {
    const serviceId = 'service_sbmvljq';
    const templateId = 'template_5rs8byj';
    
    try {
      setLoading(true);
      await emailjs.sendForm(serviceId, templateId, form.current, {
        publicKey: 'UzzjlEILfSaCOiVT1'
      });
    } catch (error) {
      setEmailError(true);
      console.log(error);
    } finally {
      setLoading(false);
    };
  };

  const handleContactUsInteraction = (e) => {
    e.preventDefault();
    if (!contacted) {
      if (validateForm(e)) {
        handleSendEmail();
        setContacted(contacted => !contacted);
      }
    } else {
    setContacted(contacted => !contacted);
    }
  };

  return (  
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='learn' element={<Learn modalStatus={modalStatus} modalName={cardModalName} modalText={cardModalText} toggleModal={handleModal} />} />
          <Route path='contact' element={<Contact contactedFlag={contacted} loadingFlag={loading} emailErrorFlag={emailError} form={form} handleInteraction={handleContactUsInteraction} />} />
          <Route path='privacy' element={<Privacy />} />
        </Route>
      </Routes>
    </BrowserRouter>
)};

export default App;