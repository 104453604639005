import React from 'react';
import { Link } from 'react-router-dom';
import { Footer } from './components.js';

function Home() {
    return (
        <div id='app'>
            <div id='radical'>
                <header><h1>Pragmatic. Imaginative. Secure. Bespoke.</h1></header>
                <main>
                    <p>
                        In today's business landscape, a secure, tailored technology infrastructure is essential to organizational efficacy. Properly deployed and utilized, technology enhances agility and sustainability, strengthens financial performance, boosts productivity, and elevates client confidence and satisfaction.
                    </p>
                    <h2>At CGQ, we curate, craft, and implement made-to-measure technology solutions that cultivate peak performance.</h2>
                    <Link to='/learn'><button>Learn More</button></Link>
                </main>
            </div>
            <Footer />
        </div>
)};

export default Home;