import { React, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Footer } from './components.js';

function Contact(props) {
    
    function ContactForm() {
        const [contactPreference, setContactPreference] = useState('email')
        const [emailPreferred, setEmailPreferred] = useState(true);

        const handlePreferenceChange = (e) => {
            e.preventDefault();
            if (e.target.value === 'email') {
            setContactPreference('email');
            setEmailPreferred(true);
            } else {
            setContactPreference('phone');
            setEmailPreferred(false);
            }
        };
        
        const formFields = [
            {
                key: 0,
                name: 'name',
                type: 'text',
                minLength: 0,
                placeholderText: 'Your Name',
                required: false
            },
            {
                key: 1,
                name: 'cname',
                type: 'text',
                minLength: 0,
                placeholderText: 'Company',
                required: false
            },
            {
                key: 2,
                name: 'email',
                type: 'email',
                minLength: 0,
                placeholderText: 'Email',
                required: emailPreferred ? true : false
            },
            {
                key: 3,
                name: 'pnumber',
                type: 'tel',
                minLength: 10,
                placeholderText: 'Phone Number',
                required: emailPreferred ? false : true
            }
        ];
        
        const formFieldStack = formFields.map(field => {
            return (
                <input className='contact-us-input' key={field.key} type={field.type} id={field.name} name={field.name} placeholder={field.placeholderText} wrap='hard' minLength={field.minLength} required={field.required} autoComplete='nope' />
        )});

        return (
            <form ref={props.form} onSubmit={props.handleInteraction}>
                <fieldset className='contact-us-form'>
                    <legend>Contact Us</legend>
                    {formFieldStack}
                    <label className='contact-us-input' htmlFor='contact-method'>How would you like us to respond?</label>
                    <select className='contact-us-input' id='contact-method' name='contactMethod' value={contactPreference} autoComplete='nope' onInput={handlePreferenceChange}>
                        <option className='contact-us-input' value='email'>email</option>
                        <option className='contact-us-input' value='phone'>Phone Call</option>
                    </select>
                    <TextareaAutosize minRows={2} className='contact-us-input' type='textarea' id='message' name='message' placeholder='Message' autoComplete='nope' />
                    <input className='button' type='submit' id='contact-form-submit' value='Submit' />
                </fieldset>
            </form>
    )};

    function Loader() {
        return (
            <div id='app'>
                <div id='radical'>
                    <header>Let's Chat</header>
                    <main>
                        <span className="loader"></span>  
                    </main> 
                </div>
            </div>
    )};

    function EmailSuccess() {
        return (
            <div id='sent-message'>
                <p>Thanks for reaching out. We will get back with you shortly.</p>
                <button onClick={props.handleInteraction}>Send New Message</button>
            </div>
    )};

    function EmailFail() {
        return (
            <div id='sent-message'>
                <p>Three things are certain:</p>
                <p>Death, taxes, and send errors.</p>
                <p>Guess which has occured.</p>
                <button onClick={props.handleInteraction}>Try Again</button>
            </div>
    )};

  if (props.loadingFlag) {
        return (
            <Loader />
        )
    } else {
        return (
            <div id='app'>
                <div id='radical'>
                    <header>{ props.contactedFlag ? ( props.emailErrorFlag ? <h1>Uh Oh!</h1> : <h1>Success!</h1> ) : <h1>Let's Chat</h1>}</header>
                    <main>   
                        { props.contactedFlag ? ( props.emailErrorFlag ? <EmailFail /> : <EmailSuccess /> ) : <ContactForm /> }
                    </main>
                </div>
                <Footer />
            </div>
    )};
};
   
export default Contact;