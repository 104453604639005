// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  width: 4.25rem;
  height: 1rem;
  position: relative;
  margin-top: 5rem;
}
.loader::before , .loader::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translate(-50% , 10%);
  top: 0;
  background: #D913FF;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  animation: jump 0.5s ease-in infinite alternate;
}

.loader::after {
  background: rgba(20, 20, 20, 0);
  color: #805EE9;
  top: 100%;
  box-shadow: 3rem -1.875rem , 3rem -1.875rem;
  animation: split 0.5s ease-out infinite alternate;
}

@keyframes split {
  0% { box-shadow: 0.75rem -1.875rem, -0.75rem -1.875rem}
  100% { box-shadow: 3rem -1.875rem , -3rem -1.875rem}
}
@keyframes jump {
 0% { transform: translate(-50% , -150%)}
 100% { transform: translate(-50% , 10%)}
}`, "",{"version":3,"sources":["webpack://./src/styles/loader.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,gCAAgC;EAChC,MAAM;EACN,mBAAmB;EACnB,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,+CAA+C;AACjD;;AAEA;EACE,+BAA+B;EAC/B,cAAc;EACd,SAAS;EACT,2CAA2C;EAC3C,iDAAiD;AACnD;;AAEA;EACE,KAAK,iDAAiD;EACtD,OAAO,4CAA4C;AACrD;AACA;CACC,KAAK,kCAAkC;CACvC,OAAO,gCAAgC;AACxC","sourcesContent":[".loader {\n  width: 4.25rem;\n  height: 1rem;\n  position: relative;\n  margin-top: 5rem;\n}\n.loader::before , .loader::after {\n  content: '';\n  position: absolute;\n  left: 50%;\n  transform: translate(-50% , 10%);\n  top: 0;\n  background: #D913FF;\n  width: 1.5rem;\n  height: 1.5rem;\n  border-radius: 50%;\n  animation: jump 0.5s ease-in infinite alternate;\n}\n\n.loader::after {\n  background: rgba(20, 20, 20, 0);\n  color: #805EE9;\n  top: 100%;\n  box-shadow: 3rem -1.875rem , 3rem -1.875rem;\n  animation: split 0.5s ease-out infinite alternate;\n}\n\n@keyframes split {\n  0% { box-shadow: 0.75rem -1.875rem, -0.75rem -1.875rem}\n  100% { box-shadow: 3rem -1.875rem , -3rem -1.875rem}\n}\n@keyframes jump {\n 0% { transform: translate(-50% , -150%)}\n 100% { transform: translate(-50% , 10%)}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
