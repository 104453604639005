import techStackImage from '../assets/images/tech-stack-2.jpg';
import boxImage from '../assets/images/box-4.jpg';
import cogencyImage from '../assets/images/chess-cropped.jpg';
import buildImage from '../assets/images/crane.jpg';
import securityImage from '../assets/images/guarded-vault.jpg';
import aiImage from '../assets/images/ai.jpg';

export const cards = [
    {
        key: 0,
        name: 'technology stack',
        backgroundImage: techStackImage,
        alt: 'futuristic tesseract-looking box',
        description: 'A technology stack refers to a set of software tools and technologies used to develop and manage applications. It typically includes programming languages, frameworks, databases, servers, and other infrastructure components that work together to support the development, deployment, and operation of software applications. The choice of a technology stack can significantly impact factors such as scalability, performance, and ease of maintenance of an application.'
    },
    {
        key: 1,
        name: 'out-of-the-box',
        backgroundImage: boxImage,
        alt: 'person with their head in a box',
        description: 'Out-of-the-box used to refer to immediate usability. A device or software was considered out-of-the-box if you could turn it on or load it up and dig in without any sort of customization. Microsoft Word is a traditionally out-of-the-box product. Today, we hear people using the term out-of-the-box to refer, essentially, to a software as a service (SaaS) solution. Nevermind that they spent $200,000 on customization and implementation, they consider their essentially proprietary system \'out-of-the-box\' simply because it\'s owned by someone else. Unfortunately, it is difficult to monetize a solution you don\'t actually own, something to consider when analyzing the cost-benefit of your out-of-the-box solution.'
    },
    {
        key: 2,
        name: 'organizational cogency',
        backgroundImage: cogencyImage,
        alt: '',
        description: 'Organizational cogency is a concept we started kicking around some time ago as a means to elucidate and quantify success. While numerous companies achieve success, each does so uniquely, underpinned, usually, by a compelling value proposition. An organization\'s cogency quotient serves as a metric for assessing this proposition, varying from formal and proprietary evaluations of organizational vitality to informal, subconscious judgments made by individual consumers. In today\'s landscape, companies with robust cogency quotients invariably adopt a technology-first approach in key aspects of their business strategy. This strategic alignment underscores the pivotal role of technology in enhancing organizational effectiveness and differentiation in the modern marketplace. Check out our About page if you\'re interested in reading more.'
    },
    {
        key: 3,
        name: 'build vs. buy',
        backgroundImage: buildImage,
        alt: '',
        description: 'When addressing organizational challenges with software, the pivotal decision lies in whether to build or buy. This choice, seemingly straightforward, carries profound implications for the future of any enterprise. It necessitates a thorough assessment of organizational architecture, strategic alignment, value proposition, and competitive differentiation. If you\'re grappling with this decision, we encourage you to connect with us through our Contact Us page. We are committed to fostering long-term partnerships based on integrity and mutual benefit. This means sometimes advising that our solutions may not be the best fit, or highlighting unforeseen alternatives that may better suit your needs. Strategic evaluation isn\'t a one-off task; it\'s a continuous process. Even if our solutions aren\'t ideal today, the landscape can change tomorrow. We believe in cultivating relationships that endure and evolve with your organization\'s growth and challenges.'
    },
    {
        key: 4,
        name: 'security posture',
        backgroundImage: securityImage,
        alt: '',
        description: 'An organization\'s security posture is a measure of the strength of its cybersecurity program, and how well it is likely to stand up to attack. It includes considerations regarding the organization\'s ability to recognize and mitigate risk associated with their systems, networks, information, third-party vendors, and service providers as well as their agility in responding to incidents and recovering from attacks.'
    },
    {
        key: 5,
        name: 'Artificial Intelligence (AI)',
        backgroundImage: aiImage,
        alt: '',
        description: 'The last couple years have been abuzz with talk of the "AI Revolution." The launch of ChatGPT in 2022 provided the general public a glimpse into the potential of machines capable of learning and reason. However, the idea of inanimate learners, whether you find it thrilling, terrifying, or completely uninteresting, is not actually that new. It has been around for almost as long as civilization. The promise of AI is considerable, the possibilities extraordinary, yet currently, its practical application is limited by the scarcity of necessary computing power and a general lack of understanding among adopters. Consequently, while AI can perform tasks like document summarization, image recognition, and content generation quite adeptly, its widespread deployment, especially in replacing human workforces, remains distant for all but tech giants like Amazon and Google. For those interested, familiarizing oneself with concepts such as machine learning, neural networks, large language models, generative AI, natural language processing, deepfakes, and computer vision is fascinating, but it is likely unrealistic to expect AI to deliver miracles for your bottom-line in 2025.'
    }
];