import React from 'react';
import { Footer } from './components.js';

function Privacy() {
    return (
        <div id='app'>
            <div id='radical'>
                <header>
                    <h1>Privacy notices can be long and convoluted.</h1>
                    <h1>This one is simple.</h1>
                </header>
                <main>
                    <span className='material-symbols-outlined'>cookie</span>
                    <p className='privacy-notice-content'> We do not track cookies.</p>
                    <span className='material-symbols-outlined'>ad_group</span>
                    <p className='privacy-notice-content'> This site does not contain advertisements from third parties not affiliated with CGQ Technologies LLC.</p>
                    <span className='material-symbols-outlined'>monitoring</span>
                    <p className='privacy-notice-content'>
                        We do not use Google Analytics, Leadfeeder, Piwik, Smartlook, Woopra, GoSquared, FoxMetrics, Mixpanel, Heap, Statcounter, Clicky, Matomo, Oribi, Serpstat, SEMrush or any other platform, service, or homespun analytics technology to track or identify you, your wearabouts, your browser usage, your IP address, your interests, your hopes, your dreams, or any of your derivative data.
                    </p>
                    <span className='material-symbols-outlined'>manage_accounts</span>
                    <p className='privacy-notice-content'>You cannot create an account on or otherwise register for this site.</p>
                    <span className='material-symbols-outlined'>sentiment_content</span>
                    <p className='privacy-notice-content'>You cannot upload or post content on this site.</p>
                    <span className='material-symbols-outlined'>attach_money</span>
                    <p className='privacy-notice-content'>We do not accept payments on this site.</p>
                    <span className='material-symbols-outlined'>contacts</span>
                    <p className='privacy-notice-content'>
                        We do our best to not collect any sensitive or personal information whatsoever on this site, with one exception: If you enter your contact information into our contact form, we will have your contact information. We will use that information to contact you. We will not share that information with third-parties, business partners, associates, affiliates, offshoots, siblings, companions, or anyone else. If you would not like us to contact you, do not share your contact information with us. We will not attempt obtain your contact information in any other way. If you have already shared your contact information with us, and are now feeling regretful, we are happy to delete your contact information at your request. To be clear, we have no interest in contacting you unless you want us to contact you. Consent is important.
                    </p>
                </main>
            </div>
            <Footer />
        </div>
)};

export default Privacy;