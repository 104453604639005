import React from 'react';
import { Outlet, Link } from 'react-router-dom';

const Layout = () => {
    const options = [
        {
          key: 0,  
          name: 'about',
          navId: 'about'
        },
        {
          key: 1,
          name: 'learn',
          navId: 'learn'
        },
        {
          key: 2,
          name: 'contact us',
          navId: 'contact'
        }
    ];

    const navOptions = options.map(option => {
        return (
            <li key={option.key}><Link className='crumb' to={`/${option.navId}`}>{option.name}</Link></li>
    )});
  
    return (
    <>
      <nav className='crumbs'>
        <Link to='/'><div id='nav-logo'></div></Link>
        <ul>
          {navOptions}
        </ul>
      </nav>

      <Outlet />
    </>
  )
};

export default Layout;